.tableBox-container {
    position: relative;
}

.tableBox-container-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.6px solid var(--primary200);
    border-radius: 5px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
}

.tableBox-container-box:hover {
    border: 1.6px solid var(--primary500);
}

.tableBox-container-box:hover + .tableBox-container-tooltip {
    visibility: visible;
    opacity: 1;
}

.tableBox-container-tooltip {
    position: absolute;
    margin-top: -50px;
    width: max-content;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-weight: 400;
    border-radius: 6px;
    padding: 5px;
    z-index: 1;
    font-size: 12px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}
