.copyComponent-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
    
    /* Tooltip container */
    .copyComponent-container-tooltip {
        position: relative;
        display: inline-block;
    }
    
    /* Tooltip text */
    .copyComponent-container-tooltip .copyComponent-container-tooltiptext {
        width: 120px;
        bottom: 100%;
        left: 50%;
        margin-left: -55px;
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        margin-bottom: 10px;
    
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 4;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .copyComponent-container:hover .copyComponent-container-tooltiptext {
        visibility: visible;
    }
    .copyComponent-container-tooltip .copyComponent-container-tooltiptext::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }