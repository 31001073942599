.funnelMetrics-container {
    display: flex;
    flex-direction: row;
    background: #E6E6E6;
    border-radius: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin: 0 10px;
    margin-bottom: 10px;
}
    .funnelMetrics-container-label {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
        .funnelMetrics-container-label-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F4F4F4;
            border-radius: 7.5px;
            padding: 5px;
            margin-right: 5px;
            color: var(--primary400);
        }
        .funnelMetrics-container-label-text {
            color: var(--gray400);
        }
    .funnelMetrics-container-value {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
        .funnelMetrics-container-value-text {
            margin-right: 5px;   
            color: var(--gray600);
        }