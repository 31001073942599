.comprasCard-container {
    display: flex;
    flex-direction: column;
    width: 60%;
}
    .comprasCard-container-title {
        color: var(--gray400);
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .comprasCard-container-list {
        display: flex;
        flex-direction: row;
    }