
.mailComponent-text {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    padding: 5px;
    width: fit-content;
    cursor: pointer;
    position: relative;
  }
    .mailComponent-text:active {
        background-color: rgba(0, 0, 0, 0.10);
    }
/* Tooltip text */
.mailComponent-text .mailComponent-text-tooltiptext {
    width: 150px;
    bottom: 100%;
    left: 50%;
    margin-left: -75px;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    margin-bottom: 10px;
  
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 2;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .mailComponent-text:hover
    .mailComponent-text-tooltiptext {
    visibility: visible;
  }
  .mailComponent-text
    .mailComponent-text-tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }