.userAdminProfile-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--white);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    min-width: 240px;
    position: fixed;
    z-index: 4;
    padding: 6px 16px;
}
    .userAdminProfile-container-userContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
        .userAdminProfile-container-userContainer-userData {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
            .userAdminProfile-container-userContainer-userData-imgWrap {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 3px solid var(--primary500);
                border-radius: 50px;
            }
                .userAdminProfile-container-userContainer-userData-imgWrap-img {
                    border-radius: 50px;
                    width: 40px;
                    height: 40px;
                }
            .userAdminProfile-container-userContainer-userData-dataWrap {
                margin-left: 10px;
                margin-right: 20px;
                width: 120px;
            }
                .userAdminProfile-container-userContainer-userData-dataWrap-name {
                    color: var(--gray700);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .userAdminProfile-container-userContainer-userData-dataWrap-rol {
                    display: flex;
                    color: var(--gray700);
                    border-radius: 5px;
                    padding: 5px;
                    width: fit-content;
                }
    .userAdminProfile-container-listContainer {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 100%;
    }