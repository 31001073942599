.itemPregunta-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
}
    .itemPregunta-container-text {
        width: 70%;
    }
    .itemPregunta-container-valor {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 15%;
    }
        .itemPregunta-container-valor-text {
            color: var(--gray700);
            margin-right: 5px;
        }
        .itemPregunta-container-valor-circle {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 20px;
            height: 20px;
            border-radius: 25px;
            border: 1px solid var(--gray300);
        }
            .itemPregunta-container-valor-circle-inner {
                background-color: var(--gray300);
                height: 80%;
                width: 80%;
                border-radius: 25px;
            }