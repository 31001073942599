.crearUser-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
    overflow-x: auto;
}
    .crearUser-container-main {
        margin-left: 103px;
        width: 100%;
    }
        .crearUser-container-main-hedear {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            position: fixed;
            background-color: transparent;
            width: 91%;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 30px;
            z-index: 4;
        }
            .crearUser-container-main-hedear-iconWrapper {
                margin-right: 270px;
            }
        .crearUser-container-main-main {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            margin-top: 120px;
        }
            .crearUser-container-main-main-title {
                font-style: normal;
                font-weight: 700;
                font-size: 2.5rem;
                margin: 0;
                color: var(--gray700);
                margin-left: 20px;
            }