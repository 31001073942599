.textWithBackGround {
    display: flex;
    flex-direction: column;
}
    .textWithBackGround-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        cursor: pointer;
        width: fit-content;
    }
        .textWithBackGround-main-text {
            width: max-content;
        }
        .textWithBackGround-main-arrow {
            transition: all 0.5 ease;
            margin-left: 5px;
        }
    .textWithBackGround-popup {
        position: relative;
        display: flex;
        flex-direction: column;
    }
        .textWithBackGround-popup-main {
            display: flex;
            padding: 16px;
            flex-direction: column;
            gap: 4px;
            position: absolute;
            background: var(--neutrals-white, #fff);
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
            border-radius: 0 0 10px 10px;
            z-index: 3;
            width: 130px;
        }
            .textWithBackGround-popup-main-title {
                color: var(--gray400);
            }