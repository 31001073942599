.polizasItem-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    justify-content: space-between;
}
    .polizasItem-container-data {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin-bottom: 20px;
    }
        .polizasItem-container-data-title {
            color: var(--gray700);
            margin: 0;
            margin-bottom: 10px;
        }
        .polizasItem-container-data-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 40px;
        }
        .polizasItem-container-data-section > * {
            width: 31%;
            margin-bottom: 10px;
            margin-right: 20px;
        }
        .polizasItem-container-data-label {
            color: var(--gray300);
        }
        .polizasItem-container-data-item {
            display: flex;
            flex-direction: column;
        }
            .polizasItem-container-data-item-title {
                color: var(--gray700);
                margin-bottom: 20px;
            }
            .polizasItem-container-data-item-data {
                display: flex;
                flex-direction: column;
                border-radius: 15px;
                border: 1px solid var(--gray200);
                background: var(--white);
                padding: 22px;
                width: 100%;
            }
            .polizasItem-container-data-item-data-head {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
                .polizasItem-container-data-item-data-head-texts {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                    .polizasItem-container-data-item-data-head-texts > * {
                        margin-right: 64px;
                    }
                .polizasItem-container-data-item-data-head-icon {
                    transition: all 0.5s ease;
                    cursor: pointer;
                }
            .polizasItem-container-data-item-data-main {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 29px;
            }
                .polizasItem-container-data-item-data-main-title {
                    color: var(--gray700);
                    margin-bottom: 22px;
                }
                .polizasItem-container-data-item-data-main-form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
                    .polizasItem-container-data-item-data-main-form > * {
                        margin-top: 20px;
                    }
                .polizasItem-container-data-item-data-main-tos {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                    .polizasItem-container-data-item-data-main-tos-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--gray300);
                        width: 20px;
                        height: 20px;
                        border-radius: 2.5px;
                        margin-right: 5px;
                    }
                    .polizasItem-container-data-item-data-main-tos-text {
                        color: var(--gray300);
                    }
                    .polizasItem-container-data-item-data-main-tos-text a {
                        text-decoration: none;
                        color: var(--primary500);
                    }