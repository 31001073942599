.dashboard-container {
    display: flex;
    background-color: var(--gray50);
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
}
    .dashboard-container-main {
        margin-left: 100px;
        width: 100%;
        padding-bottom: 50px;
    }
        .dashboard-container-main-hedear {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            position: fixed;
            width: 94%;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 60px;
            margin-left: -20px;
            z-index: 4;
            background-color: var(--gray50);
        }
            .dashboard-container-main-hedear-iconWrapper {
                margin-right: 270px;
            }
        .dashboard-container-main-data {
            padding-left: 21px;
            background-color: var(--gray50);
        }
            .dashboard-container-main-data-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 120px;
                margin-bottom: 20px;
            }
                .dashboard-container-main-data-title-text {
                    color: var(--gray700);
                    margin-right: 20px;
                }
                .dashboard-container-main-data-title-selectorWrapper {
                    margin-top: -30px;
                }
            .dashboard-container-main-data-cardList {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
                .dashboard-container-main-data-cardList-list {
                    margin-bottom: 20px;
                }
    .dashboard-container-rightMenu {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 270px;
        background-color: var(--primary200);
        right: 0;
        height: 100vh;
        padding-top: 32px;
        position: fixed;
    }