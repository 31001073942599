.globalStatusChange {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}
.globalStatusChange:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
    .globalStatusChange-img {
        width: 100%;
        transition: all 0.5s ease;
    }
        .globalStatusChange-img:active {
            transform: scale(0.8)
        }
    .globalStatusChange-list {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 30px;
        border-radius: 15px;
        background-color: var(--white);
        box-shadow: 0px 15px 30px 0px rgba(51, 51, 51, 0.20);
        overflow: hidden;
        width: max-content;
    }
        .globalStatusChange-list-text {
            color: var(--gray600);
            padding: 6px 16px;
            cursor: pointer;
        }
        .globalStatusChange-list-text:hover {
            background-color: var(--gray100);
        }
        .globalStatusChange-list-text:active {
            background-color: var(--primary200);
        }
            .globalStatusChange-list-text-estado {
                padding: 3px;
                border-radius: 5px;
                margin-left: 5px;
            }
.globalStatusChange-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 25px;
    background-color: var(--primary500);
}
    .globalStatusChange-loader-loader {
        width: 65%;
        height: 65%;
        border: 2.5px solid var(--white);
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 