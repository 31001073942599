.detailAccions-container {
    display: flex;
    flex-direction: column;
    position: relative;
}
    .detailAccions-container-main {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 25px;
        border: 2px solid var(--primary500);
        cursor: pointer;
        position: relative;
        transition: all 0.5s ease;
    }
    .detailAccions-container-list {
        display: flex;
        flex-direction: column;
        position: absolute;
        border-radius: 15px;
        overflow: hidden;
        right: 10px;
        margin-top: 45px;
        box-shadow: 0px 15px 30px 0px rgba(51, 51, 51, 0.20);
        width: max-content;
    }
        .detailAccions-container-list-item {
            display: flex;
            background-color: var(--gray50);
            padding: 10px;
            cursor: pointer;
        }
        .detailAccions-container-list-item:hover {
            background-color: var(--gray100);
        }
        .detailAccions-container-list-item:active {
            background-color: var(--primary200);
        }
            .detailAccions-container-list-item-text {
                color: var(--gray600);
            }
    .detailAccions-container-load {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 25px;
        border: 2px solid var(--primary500);
        background-color: var(--primary500);
    }

.loader {
    width: 70%;
    height: 70%;
    border: 3.5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
