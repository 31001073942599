.genericMetricCard-container {
    display: flex;
    flex-direction: flex;
    align-items: center;
    justify-content: flex-start;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 15px;
    padding: 10px 0;
    padding-left: 10px;
    margin-right: 10px;
    width: 100%;
}
    .genericMetricCard-container-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F4F4F4;
        border-radius: 7.5px;
        padding: 5px;
        margin-right: 5px;
        color: #F0B49E;
    }
    .genericMetricCard-container-data {
    }
        .genericMetricCard-container-data-title {
            color: var(--gray600);
        }
        .genericMetricCard-container-data-description {
            color: var(--gray400);
        }
    /* Tooltip container */
    .genericMetricCard-container-tooltip {
        position: relative;
        display: inline-block;
        margin-left: 5px;
        margin-bottom: auto;
    }
    
    /* Tooltip text */
    .genericMetricCard-container-tooltip .genericMetricCard-container-tooltiptext {
        width: 120px;
        bottom: 100%;
        left: 50%;
        margin-left: -55px;
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        margin-bottom: 10px;
    
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .genericMetricCard-container-tooltip:hover .genericMetricCard-container-tooltiptext {
        visibility: visible;
    }
    .genericMetricCard-container-tooltip .genericMetricCard-container-tooltiptext::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }