.descargarCotizacionModal-container {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    padding: 32px 16px
}
    .descargarCotizacionModal-container-title {
        color: var(--gray700);
        text-align: center;
        margin-bottom: 16px;
    }
    .descargarCotizacionModal-container-subTitle {
        color: var(--gray300);
        margin-bottom: 8px;
    }
    .descargarCotizacionModal-container-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        width: 80%;
    }
        .descargarCotizacionModal-container-buttons > * {
            width: 45%;
        }
    .descargarCotizacionModal-container-list {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }
        .descargarCotizacionModal-container-list-insurerLink {
            display: flex;
            flex-direction: column;
            border-top: 1px solid var(--gray100);
            padding-top: 8px;
        }
            .descargarCotizacionModal-container-list-insurerLink-title {
                color: var(--gray400);
                margin-bottom: 8px;
            }
            .descargarCotizacionModal-container-list-insurerLink-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 8px;
                width: fit-content;
            }
                .descargarCotizacionModal-container-list-insurerLink-item-check {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid var(--primary500);
                    width: 15px;
                    height: 15px;
                    border-radius: 1px;
                }
                .descargarCotizacionModal-container-list-insurerLink-item-text {
                    color: var(--gray600);
                    margin-left: 6px;
                }
    .descargarCotizacionModal-container-selectAll {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        border-top: 1px solid var(--gray100);
        padding-top: 8px;
        cursor: pointer;
    }
        .descargarCotizacionModal-container-selectAll-check {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--primary500);
            width: 15px;
            height: 15px;
            border-radius: 1px;
        }
        .descargarCotizacionModal-container-selectAll-text {
            color: var(--gray600);
            margin-left: 6px;
        }