.chip {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary200);
    margin: 4px;
    padding: 5px;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
    font-size: 12px;
    color: var(--gray700);
    gap: 4px;
  }