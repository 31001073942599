.listMetrics-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #C4C4C4;
    border-radius: 15px;
    width: fit-content;
    width: 100%;
    padding: 10px;
    margin-left: 20px;
}
    .listMetrics-container-title {
        color: var(--gray600);
    }
    .listMetrics-container-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #E6E6E6;
        border-radius: 12px;
        margin: 5px 0;
        min-height: 34px;
    }
        .listMetrics-container-item-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            color: var(--gray600);
        }
            .listMetrics-container-item-label-text {
                margin-left: 5px;
                color: var(--gray400);
            }
        .listMetrics-container-item-value {
            margin-right: 5px;
            color: var(--gray600);
        }