.crearCotizaciones-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
    position: fixed;
}
    .crearCotizaciones-container-main {
        display: flex;
        flex-direction: column;
        margin-left: 103px;
        width: 100%;
        height: 100%;
    }
        .crearCotizaciones-container-main-hedear {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            position: fixed;
            background-color: transparent;
            width: 91%;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 30px;
            z-index: 4;
        }
            .crearCotizaciones-container-main-hedear-iconWrapper {
                margin-right: 270px;
            }
        .crearCotizaciones-container-main-main {
            display: flex;
            flex-direction: column;
            height: auto;
            padding-top: 120px;
            flex: 1;
        }
            .crearCotizaciones-container-main-main-iframe {
                width: 97.5%;
                margin: 0 auto;
                height: 100%;
                margin-top: 10px;
                border: none;
            }
            .crearCotizaciones-container-main-main-top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 99%;
            }
                .crearCotizaciones-container-main-main-top-left {
                    display: flex;
                    flex-direction: column;
                }
                    .crearCotizaciones-container-main-main-top-left-title {
                        color: var(--gray700);
                        margin-left: 20px;
                    }
                    .crearCotizaciones-container-main-main-top-left-volver {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-left: 15px;
                        cursor: pointer;
                    }
                        .crearCotizaciones-container-main-main-top-left-volver-text {
                            color: var(--primary500);
                            margin-left: -5px;
                        }
                .crearCotizaciones-container-main-main-top-right {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                    .crearCotizaciones-container-main-main-top-right-download {
                        border-radius: 20px;
                        color: var(--white);
                        padding: 11px 24px;
                        cursor: pointer;
                        background-color: var(--primary500);
                    }
                        .crearCotizaciones-container-main-main-top-right-download:active {
                            transform: scale(0.95);
                            background-color: var(--primary700);
                        }
                    .crearCotizaciones-container-main-main-top-right-downloadStart {
                        border-radius: 20px;
                        color: var(--white);
                        padding: 11px 24px;
                        cursor: pointer;
                        animation-name: backgroundColorPalette;
                        animation-duration: 2s;
                        animation-iteration-count: infinite;
                        animation-direction: normal;
                        animation-timing-function: linear;  
                        background: linear-gradient(to right, var(--primary700) 55%, var(--primary500) 10%) left;
                        background-size: 200%;
                    }
                    @keyframes backgroundColorPalette {
                        0% {background-position: right;}
                        100% {background-position: left;}
                    }
                    .crearCotizaciones-container-main-main-top-right-opcions {
                        display: flex;
                        flex-direction: column;
                        margin-left: 16px;
                        position: relative;
                    }
                        .crearCotizaciones-container-main-main-top-right-opcions-button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: var(--gray50);
                            border: 2px solid var(--primary500);
                            border-radius: 25px;
                            width: 40px;
                            height: 40px;
                            cursor: pointer;
                        }
                        .crearCotizaciones-container-main-main-top-right-opcions-list {
                            display: flex;
                            flex-direction: column;
                            background-color: var(--white);
                            position: absolute;
                            width: max-content;
                            border-radius: 15px;
                            box-shadow: 0px 8px 24px 0px rgba(51, 51, 51, 0.20);
                            right: 0px;
                            top: 47px;
                            overflow: hidden;
                        }
                            .crearCotizaciones-container-main-main-top-right-opcions-list-item {
                                cursor: pointer;
                                color: var(--gray600);
                                background-color: transparent;
                                padding: 6px 16px;
                            }
                            .crearCotizaciones-container-main-main-top-right-opcions-list-item:hover {
                                background-color: var(--gray100);
                            }
                            .crearCotizaciones-container-main-main-top-right-opcions-list-item:active {
                                background-color: var(--primary200);
                                color: var(--gray700);
                            }
                        .crearCotizaciones-container-main-main-top-right-opcions-loading {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;
                            border-radius: 25px;
                            border: 2px solid var(--primary500);
                            background-color: var(--primary500);
                        }
                            .crearCotizaciones-container-main-main-top-right-opcions-loading-loader {
                                width: 70%;
                                height: 70%;
                                border: 3.5px solid #FFF;
                                border-bottom-color: transparent;
                                border-radius: 50%;
                                display: inline-block;
                                box-sizing: border-box;
                                animation: rotation 1s linear infinite;
                            }
                            @keyframes rotation {
                                0% {
                                    transform: rotate(0deg);
                                }
                                100% {
                                    transform: rotate(360deg);
                                }
                            } 