.CheckBoxComponent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CheckBoxComponent-container-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  border: 1px solid var(--primary500);
  cursor: pointer;
  border-radius: 2px;
}
.CheckBoxComponent-container-box-check {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--primary500);
  color: var(--white);
  transition: all 150ms ease;
}
.CheckBoxComponent-container-text {
  margin-left: 5px;
  color: var(--gray600);
}
