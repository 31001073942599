.responsive-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
    .responsive-container-logo {
        margin-top: 90px;
    }
    .responsive-container-title {
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        color: var(--primary500);
        margin: 0;
        padding: 0;
        margin-top: 80px;
    }
    .responsive-container-subTitle {
        font-style: normal;
        font-weight: 300;
        font-size: 1.25rem;
        color: var(--gray700);
        padding: 0;
        margin: 0;
        margin-top: 10px;
        text-align: center;
    }