.login-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
    .login-container-main {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
    }
        .login-container-main-buttonWrapper {
            width: 30%;
        }
        .login-container-main-OlvidasteEmailButtonWrapper {
            margin: 15px 0;
        }
        .login-container-main-captchaWrapper {
            margin: 20px 0;
            max-height: 304px;
        }
        .login-container-main-logo {
            width: 10%;
            margin: 0 auto;
        }
        .login-container-main-title {
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            color: var(--gray700);
            margin: 0;
            padding: 0;
            margin-top: 20px;
        }
        .login-container-main-subTitle {
            font-style: normal;
            font-weight: 300;
            font-size: 1.25rem;
            color: var(--gray700);
            padding: 0;
            margin: 0;
            margin-top: 10px;
            margin-bottom: 50px;
        }
        .login-container-main-profileContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            min-height: 50px;
            border-radius: 50px;
            border: 3px solid var(--primary500);
            margin-top: -20px;
        }
            .login-container-main-profileContainer-img {
                width: 45px;
                height: 45px;
                border-radius: 50px;
            }
        .login-container-main-emailContainer {
            display: flex;
            flex-direction: row;
            margin: 15px 0;
        }
            .login-container-main-emailContainer-text {
                font-style: normal;
                font-weight: 700;
                font-size: 1rem;
                color: var(--gray700);
                margin: 0;
                padding: 0;
                margin-left: 10px;
                margin-bottom: 5px;
            }
        .login-container-main-inputWrapper {
            width: 300px;
        }
        .login-container-main-accountRememberContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }
            .login-container-main-accountRememberContainer-box {
                display: flex;
                align-content: center;
                justify-content: center;
                border: 2px solid var(--primary500);
                box-sizing: border-box;
                border-radius: 4px;
                width: 26px;
                height: 26px;
                cursor: pointer;
            }
                .login-container-main-accountRememberContainer-box-inner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.25s ease;
                }
            .login-container-main-accountRememberContainer-text {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                color: var(--gray700);
                margin-left: 10px;
            }
    .login-container-cardsContainer {
        width: 50%;
        background-color: var(--primary200);
    }
        .login-container-cardsContainer-img {
            margin: 0 auto;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }