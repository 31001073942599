.hedearIcons-container-main-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
}
    .hedearIcons-container-main-icons-country {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: var(--primary500);
        border-radius: 11.7647px;
        padding: 5px;
        padding-right: 10px;
        margin: 0 10px;
    }