.burgerMenuItem-container {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    width: 100%;
}
    .burgerMenuItem-container-iconText {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        border-radius: 15px;
        width: 70%;
        height: 95%;
        margin: 0 auto;
        color: var(--gray300);
        padding-left: 10px;
    }
    .burgerMenuItem-container-iconText:hover {
        background-color: var(--gray50);
    }
    .burgerMenuItem-container-iconText:active {
        background-color: var(--gray50);
        color: var(--primary500)
    }
    .burgerMenuItem-container-iconTextDis {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 15px;
        width: 70%;
        height: 95%;
        margin: 0 auto;
        color: var(--gray300);
        padding-left: 10px;
    }
    .burgerMenuItem-container-iconTextSelected {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 15px;
        width: 70%;
        height: 95%;
        margin: 0 auto;
        color: var(--primary500);
        padding-left: 10px;
    }
    .burgerMenuItem-container-selectedIcon {
        width: 5px;
        height: 90%;
        border-radius: 4px 0px 0px 4px;
        margin-left: -5px;
    }
    .burgerMenuItem-container-muyPronto {
        position: absolute;
        font-style: normal;
        font-weight: 700;
        font-size: 0.4rem;
        color: var(--white);
        background-color: var(--primary500);
        padding: 5px;
        border-radius: 25px;
        left: 100px;
        margin-top: 39px;
        z-index: 4;
        margin-left: auto;
    }
    .burgerMenuItem-container-item-text {
        text-align: left;
        margin-left: 5px;
    }
    .burgerMenuItem-container-notify {
        height: 5px;
        width: 5px;
        background-color: var(--accent500);
        border-radius: 25px;
        margin-bottom: 15px;
    }