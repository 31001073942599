.headers-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .headers-container-list {
        display: flex;
        flex-direction: row;
    }
        .headers-container-list-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
        }
            .headers-container-list-item-text {
                text-align: center;
                margin: 0 15px;
                margin-bottom: 10px;
                transition: all 0.5s ease;
            }
            .headers-container-list-item-underline {
                height: 4px;
                z-index: 2;
                width: 100%;
                transition: all 0.5s ease;
            }
    .headers-container-underline {
        width: 100%;
        border: 1px solid var(--gray200);
        margin-top: -4px;
    }