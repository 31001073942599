.passwordRecoveryFinish-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
    .passwordRecoveryFinish-container-main {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .passwordRecoveryFinish-container-main-title {
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            color: var(--gray700);
            margin: 0;
            padding: 0;
            margin-top: 20px;
        }
        .passwordRecoveryFinish-container-main-subTitle {
            font-style: normal;
            font-weight: 300;
            font-size: 1.25rem;
            color: var(--gray700);
            padding: 0;
            margin: 0;
            margin-top: 30px;
            margin-bottom: 40px;
            text-align: center;
        }
    .passwordRecoveryFinish-container-cardsContainer {
        width: 50%;
        background-color: var(--primary200);
    }
        .passwordRecoveryFinish-container-cardsContainer-img {
            margin: 0 auto;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }