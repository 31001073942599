.genericItem-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
    .genericItem-container-item {
        padding: 5px 10px;
        border-radius: 12px;
        margin-right: 5px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
        .genericItem-container-item-label {
            text-align: center;
        }