.deleteRowModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .deleteRowModal-container-title {
        color: var(--gray700);
        text-align: center;
        margin-top: 18px;
    }
    .deleteRowModal-container-subTitle {
        color: var(--gray300);
        text-align: center;
        margin-top: 18px;
        margin-bottom: 42px;
    }
    .deleteRowModal-container-inputList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    .deleteRowModal-container-buttonList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .deleteRowModal-container-buttonList > *{
        width: 35%;
    }
    .deleteRowModal-container-buttonWrap {
        width: 40%;
    }