.estadoModal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
    .estadoModal-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .estadoModal-container-title {
        text-align: center;
        margin-bottom: 20px;
        color: var(--gray700);
    }
    .estadoModal-container-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 5px;
        color: var(--gray400);
    }
    .estadoModal-container-textarea {
        width: 100%;
        height: 150px;
        margin: 20px 0;
        resize: none;
        border: 1px solid #C4C4C4;
        border-radius: 15px;
        padding: 5px;
    }
    .estadoModal-container-buttons {
        display: flex;
        flex-direction: row;
        width: 80%;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
    }
        .estadoModal-container-buttons-b1 {
            width: 50%;
        }