.ordenarPor-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left:auto;
}
  .ordenarPor-container-labels {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
    .ordenarPor-container-labels-ordenar {
      color: var(--gray700);
    }
    .ordenarPor-container-labels-action {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
      .ordenarPor-container-labels-action-text {
        color: var(--primary500);
      }
  .ordenarPor-container-list {
    display: flex;
    flex-direction: column;
    background: var(--white);
    box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.25);
    border-radius: 15px;
    position: absolute;
    margin-top: 30px;
    z-index: 3;
    overflow: hidden;
  }
    .ordenarPor-container-list-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin: 0;
      color: var(--black);
      padding: 10px 20px;
      cursor: pointer;
    }
    .ordenarPor-container-list-text:hover {
      background-color: var(--gray100);
    }
