.cancelModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
    .cancelModal-container-title {
        margin-top: 24px;
        margin-bottom: 8px;
    }
    .cancelModal-container-text {
        margin-bottom: 24px;
    }
    .cancelModal-container-botones {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
        .cancelModal-container-botones > * {
            width: 30%;
            margin-right: 10px;
        }