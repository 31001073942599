.socialLinks-container{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    transition: all 0.5s ease-in;
}
    .socialLinks-container-list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 2.5px;
    }
        .socialLinks-container-list-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.5s ease-in;
            position: absolute;
            margin-right: 100px;
            text-decoration: none;
        }
            .socialLinks-container-list-item-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
                border-radius: 100px;
                min-height: 40px;
                min-width: 40px;
                margin-left: 10px;
                color: var(--primary500);
            }
            .socialLinks-container-list-item-label {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;
                border-radius: 10px;
                margin-left: -20px;
                min-width: fit-content;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                min-height: 40px;
                padding: 0 10px;
            }
                .socialLinks-container-list-item-label-text {
                    text-decoration: none;
                    color: var(--primary500);
                    white-space: nowrap; 
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
    .socialLinks-container-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 7.18533px 26.945px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        width: 50px;
        height: 50px;
        z-index: 10;
        cursor: pointer;
    }
