.topThreeMetrics-container {
    display: flex;
    flex-direction: column;
    background: #E6E6E6;
    border-radius: 15px;
    padding: 10px;
}
    .topThreeMetrics-container-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
        .topThreeMetrics-container-title-text {
            display: flex;
            flex-direction: row;
            color: var(--gray600);
        }
        .topThreeMetrics-container-title-text ul {
            margin: 0;
            padding: 0;
        }
    .topThreeMetrics-container-list {

    }   
        .topThreeMetrics-container-list-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;
            color: var(--gray600);
        }
            .topThreeMetrics-container-list-item-label {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
                .topThreeMetrics-container-list-item-label-index {
                    margin-right: 5px;
                    color: var(--primary500);
                }
                .topThreeMetrics-container-list-item-label-text {
                    color: var(--gray600);
                } 
                
    /* Tooltip container */
    .topThreeMetrics-container-tooltip {
        position: relative;
        display: inline-block;
        margin-left: 5px;
        margin-bottom: auto;
    }
    
    /* Tooltip text */
    .topThreeMetrics-container-tooltip .topThreeMetrics-container-tooltiptext {
        width: 220px;
        bottom: 100%;
        right: -560%;
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        margin-bottom: 10px;
    
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .topThreeMetrics-container-tooltip:hover .topThreeMetrics-container-tooltiptext {
        visibility: visible;
    }
    .topThreeMetrics-container-tooltip .topThreeMetrics-container-tooltiptext::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }