.menuItem-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
    .menuItem-container-iconText {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        border-radius: 15px;
        width: 100%;
        height: 95%;
        color: var(--gray300);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    .menuItem-container-iconText:hover {
        background-color: var(--gray50);
    }
    .menuItem-container-iconText:active {
        background-color: var(--gray50);
        color: var(--primary500)
    }
    .menuItem-container-iconTextDis {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 15px;
        width: 100%;
        height: 95%;
        margin: 0 auto;
        color: var(--gray300);
        padding-left: 10px;
    }
    .menuItem-container-iconTextSelected {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 15px;
        width: 100%;
        height: 95%;
        margin: 0 auto;
        color: var(--primary500);
        padding-left: 10px;
    }
    .menuItem-container-muyPronto {
        position: absolute;
        font-style: normal;
        font-weight: 700;
        font-size: 0.4rem;
        color: var(--white);
        background-color: var(--primary500);
        padding: 5px;
        border-radius: 25px;
        left: 100px;
        margin-top: 39px;
        z-index: 4;
        margin-left: auto;
    }