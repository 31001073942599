.polizasDetails-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
}
    .polizasDetails-container-main {
        display: flex;
        flex-direction: column;
        margin-left: 103px;
        width: 100%;
    }
        .polizasDetails-container-main-hedear {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            position: fixed;
            background-color: transparent;
            width: 91%;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 30px;
            z-index: 4;
        }
            .polizasDetails-container-main-hedear-iconWrapper {
                margin-right: 270px;
            }
        .polizasDetails-container-main-body {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;
            margin-top: 90px;
            width: 97.5%;
        }
            .polizasDetails-container-main-body-content {
                padding-left: 20px;
                padding-top: 20px;
            }
            .polizasDetails-container-main-body-top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-left: 20px;
                position: relative;
            }
                .polizasDetails-container-main-body-top-download {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 25px;
                    border: 2px solid var(--primary500);
                    cursor: pointer;
                    position: relative;
                    transition: all 0.5s ease;
                }
                .polizasDetails-container-main-body-top-download:active {
                    transform: scale(0.9);
                }
                .polizasDetails-container-main-body-top-download:hover .polizasDetails-tooltip {
                    visibility: visible;
                    opacity: 1;
                }
                .polizasDetails-container-main-body-top-text {

                }
                    .polizasDetails-container-main-body-top-text-title {
                        color: var(--gray700);
                        margin: 0;
                    }
                    .polizasDetails-container-main-body-top-text-volver {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        margin-top: 10px;
                        width: fit-content;
                    }
                        .polizasDetails-container-main-body-top-text-volver-text {
                            color: var(--primary500);
                            margin: 0;
                        }
.loader {
    width: 70%;
    height: 70%;
    border: 3.5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

.polizasDetails-tooltip {
    position: absolute;
    margin-top: 70px;
    margin-left: -100px;
    width: max-content;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-weight: 400;
    border-radius: 6px;
    padding: 5px;
    z-index: 1;
    font-size: 12px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}