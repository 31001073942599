.userPolizasComponent-container {
    display: flex;
    flex-direction: column;
    width: 85%;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 15px;
    align-items: center;
    margin: 20px 0;
}
    .userPolizasComponent-container-closed {
        display: flex;
        flex-direction: row;
        width: 95%;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
    }
        .userPolizasComponent-container-closed-arrowContainer {
            display: flex;
            transition: all 150ms ease;
            width: 100%;
            justify-content: flex-end;
        }
            .userPolizasComponent-container-closed-arrowContainer-wrapper {
                cursor: pointer;
            }
        .userPolizasComponent-container-closed-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--gray700);
            width: 100%;
            text-transform: capitalize;
        }
            .userPolizasComponent-container-closed-text ul {
                margin: 0;
                padding: 0;
            }
    .userPolizasComponent-container-opened {
        display: flex;
        flex-direction: row;
        width: 95%;
        align-items: center;
        justify-content: space-between;
        padding-top: 40px;
        padding-bottom: 20px;
    }
        .userPolizasComponent-container-opened-text {
            display: flex;
            flex-direction: row;
            color: var(--gray300);
            width: 100%;
        }
            .userPolizasComponent-container-opened-text ul {
                margin: 0;
                padding: 0;
            }
        .userPolizasComponent-container-opened-buttonContainer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
            .userPolizasComponent-container-opened-buttonContainer-wrapper {
                width: 30%;
            }