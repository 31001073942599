.guardarCotizacionModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .guardarCotizacionModal-container-title {
        color: var(--gray700);
        margin-bottom: 8px;
    }
    .guardarCotizacionModal-container-subTitle {
        color: var(--gray300);
        margin-bottom: 32px;
    }
    .guardarCotizacionModal-container-inputWrapper {
        width: 80%;
        margin-bottom: 32px;
    }
    .guardarCotizacionModal-container-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80%;
    }
        .guardarCotizacionModal-container-buttons > * {
            width: 45%;
        }