.tableFilters-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  margin-top: 20px;
  margin-left: 20px;
  flex-wrap: wrap;
}
.tableFilters-filters-excluded {
  display: flex;
  flex-direction: row;
}
.tableFilters-filters-excluded-textInputWrapper {
  width: 351px;
}
.tableFilters-filters-included {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
}
.tableFilters-filters-included-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.tableFilters-filters-included-main:active { 
  transform: scale(0.9);
}
.tableFilters-filters-included-main-title {
  color: var(--primary500);
  margin-left: 8px;
}

.tableFilters-filters-included-menu-title-text{
  color: var(--gray700);
}


.tableFilters-filters-included-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  border-radius: 15px;
  background: var(--white);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 16px;
  width: 340px;
  z-index: 3;
}
.tableFilters-filters-included-menu-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tableFilters-filters-included-menu-list {
  display: flex;
  flex-direction: column;
}
.tableFilters-filters-included-menu-list-item {
  display: flex;
  flex-direction: column;
}
.tableFilters-filters-included-menu-list-item-line {
  background: #e6e6e6;
  height: 1px;
  width: 100%;
  margin: 8px 0;
}
.tableFilters-filters-included-menu-list-item-title {
  color: var(--gray700);
}
.tableFilters-filters-included-menu-aplicar {
  width: 10%;
  margin-left: auto;
  margin-right: 16px;
}
.tableFilters-filters-download {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--primary500);
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 20px;
  position: relative;
  display: flex;
}
/* Tooltip text */
.tableFilters-filters-download .tableFilters-filters-download-tooltiptext {
  width: 200px;
  bottom: 100%;
  left: 20%;
  margin-left: -100px;
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  margin-bottom: 10px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 2;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tableFilters-filters-download:hover
  .tableFilters-filters-download-tooltiptext {
  visibility: visible;
}
.tableFilters-filters-download
  .tableFilters-filters-download-tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tableFilters-filters-download:active {
  transform: scale(0.95);
  background: var(--primary700);
}
.tableFilters-filters-download-text {
  font-style: normal;
  font-weight: 700;
  font-size: 0.95rem;
  color: var(--white);
  margin: 0;
  margin-left: 5px;
}
    .tableFilters-filters-excluded {
        display: flex;
        flex-direction: row;
    }
        .tableFilters-filters-excluded-textInputWrapper {
            width: 351px;
        }
    .tableFilters-filters-included {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0px 20px;
    }
        .tableFilters-filters-included-main {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary500);
            cursor: pointer;
        }
            .tableFilters-filters-included-main-title {
                color: var(--primary500);
                margin-left: 2px;
            }
        .tableFilters-filters-included-menu {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 30px;
            border-radius: 15px;
            background: var(--white);
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
            padding: 16px;
            width: 340px;
            z-index: 2;
        }
            .tableFilters-filters-included-menu-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
                .tableFilters-filters-included-menu-title-text {
                    /*  */
                }
            .tableFilters-filters-included-menu-list {
                display: flex;
                flex-direction: column;
            }
                .tableFilters-filters-included-menu-list-item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
                    .tableFilters-filters-included-menu-list-item-line {
                        background: #E6E6E6;
                        height: 1px;
                        width: 100%;
                        margin: 8px 0;
                    }
                    .tableFilters-filters-included-menu-list-item-title {
                        color: var(--gray400);
                    }
                    .tableFilters-filters-included-menu-list-item-opcionList {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    }
            .tableFilters-filters-included-menu-aplicar {
                width: 10%;
                margin-left: auto;
                margin-right: 16px;
            }
        
.tableFilters-filters-loader {
  width: 20px;
  height: 20px;
  border: 3px solid var(--white);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tableFilters-chips {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  margin-top: 20px;
  margin-left: 20px;
  flex-wrap: wrap;
}

.borrar-filtros-text{
    color:var(--primary500);
    font-size: 14px;
    font-weight: bold; 
}

.borrar-filtros-text:active{
    color:var(--primary700);
    font-size: 12px;
    font-weight: bold;
}

.tableFilters-filters-downloadTxt {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 25px;
  padding: 10px;
  cursor: pointer;
  margin-left: 20px;
  position: relative;
  display: flex;
  border: 1px solid var(--primary500);
}
/* Tooltip text */
.tableFilters-filters-downloadTxt .tableFilters-filters-downloadTxt-tooltiptext {
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  margin-bottom: 10px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 2;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tableFilters-filters-downloadTxt:hover
  .tableFilters-filters-downloadTxt-tooltiptext {
  visibility: visible;
}
.tableFilters-filters-downloadTxt
  .tableFilters-filters-downloadTxt-tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tableFilters-filters-downloadTxt:active {
  transform: scale(0.95);
}
.tableFilters-filters-downloadTxt-text {
  font-style: normal;
  font-weight: 700;
  font-size: 0.95rem;
  color: var(--white);
  margin: 0;
}