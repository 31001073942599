.userCotizaciones-container {
    width: 100%;
}
    .userCotizaciones-container-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }
        .userCotizaciones-container-empty-text {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: var(--gray300);
        }