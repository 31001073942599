.selector-container {
    display: flex;
    flex-direction: column;
    width: 15%;
    z-index: 2;
    position: absolute;
}
    .selector-container-labelsClose {
        display: flex;
        flex-direction: row;
        background: var(--gray50);
        border: 1px solid var(--primary500);
        border-radius: 25px;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        width: 100%;
    }
    .selector-container-labels {
        display: flex;
        flex-direction: row;
        background: var(--primary200);
        border: none;
        border-radius: 25px 25px 0 0;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        width: 100%;
    }
        .selector-container-labels-text {
            color: var(--primary500);
        }
    .selector-container-list {
        width: 100%;
        flex-direction: column;
        background: transparent;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
        .selector-container-list-item {
            width: 100%;
            padding: 8px 16px;
            justify-content: space-between;
            align-items: center;
            display: flex;
            flex-direction: row;
            background: var(--white);
            cursor: pointer;
        }
            .selector-container-list-item-text {
                color: var(--gray700);
            }
        .selector-container-list-item:hover {
            background-color: var(--gray100);
            cursor: pointer;
        }
        .selector-container-list-itemSelected {
            width: 100%;
            padding: 8px 16px;
            justify-content: space-between;
            align-items: center;
            display: flex;
            flex-direction: row;
            background: var(--primary200);
        }