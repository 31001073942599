.graphMetrics-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    border-radius: 15px;
    margin: 5px 0;
    padding: 10px;
}
    .graphMetrics-container-data {
        
    }
        .graphMetrics-container-data-value {
            color: var(--gray600);
        }
        .graphMetrics-container-data-label {
            color: var(--gray600);
        }