.titleAndSubTitleComponent-container {

}
    .titleAndSubTitleComponent-container-title {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        color: var(--gray700);
        margin: 0;
    }
    .titleAndSubTitleComponent-container-subTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 0.95rem;
        color: var(--gray300);
        margin: 0;
    }