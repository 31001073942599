.individualActions-container {
    display: flex;
    flex-direction: column;
    position: relative;
}
    .individualActions-container-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 25px;
        width: 40px;
        height: 40px;
        transition: all 0.5s ease;
    }
        .individualActions-container-icon:hover {
            background-color: var(--gray100);
        }
    .individualActions-container-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        background: var(--gray50);
        box-shadow: 0px 15px 30px 0px rgba(51, 51, 51, 0.20);
        right: 10px;
        top: 35px;
        width: fit-content;
        overflow: hidden;
        z-index: 2;
    }
        .individualActions-container-list-item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            width: 100%;
            cursor: pointer;
        }
            .individualActions-container-list-item:hover {
                background-color: var(--gray100);
            }
            .individualActions-container-list-item:active {
                background-color: var(--primary200);
            }
            .individualActions-container-list-item-text {
                color: var(--gray700);
            }