.userClaimsComponent-container {
    display: flex;
    flex-direction: column;
    width: 85%;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 15px;
    align-items: center;
    margin: 20px 0;
}
    .userClaimsComponent-container-closed {
        display: flex;
        flex-direction: row;
        width: 95%;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
    }
        .userClaimsComponent-container-closed-status {
            display: flex;
            flex-direction: row;
            width: 40%;
            justify-content: space-between;
        }
            .userClaimsComponent-container-closed-status-arrowContainer {
                transition: transform 150ms ease;
            }
                .userClaimsComponent-container-closed-arrowContaine-statusr-wrapper {
                    cursor: pointer;
                }
            .userClaimsComponent-container-closed-status-textStatus {
                color: var(--white);
                margin: 0;
                padding: 2.5px 5px;
                border-radius: 5px;
            }
        .userClaimsComponent-container-closed-text {
            color: var(--gray700);
            margin: 0;
            width: 100%;
        }
    .userClaimsComponent-container-opened {
        display: flex;
        flex-direction: row;
        width: 95%;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 15px;
    }
        .userClaimsComponent-container-opened-text {
            color: var(--gray300);
            margin: 0;
            width: 100%;
        }
        .userClaimsComponent-container-opened-buttonContainer {
            display: flex;
            justify-content: flex-end;
            width: 40%;
        }
            .userClaimsComponent-container-opened-buttonContainer-wrapper {
                width: 50%;
            }