.claimsData-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    justify-content: space-between;
}
    .claimsData-container-data {
        display: flex;
        flex-direction: column;
        width: 70%;
    }
        .claimsData-container-data-text {
            color: var(--gray300);
        }
        .claimsData-container-data-title {
            color: var(--gray700);
            margin: 0;
            margin-bottom: 10px;
        }
        .claimsData-container-data-textarea {
            width: 62%;
            background: #F4F4F4;
            border: 1px solid #C4C4C4;
            border-radius: 15px;
            padding: 10px;
            max-height: 400px;
            overflow-y: auto;
            margin: 20px 0;
        }
        .claimsData-container-data-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 40px;
        }
            .claimsData-container-data-section > * {
                width: 31%;
                margin-bottom: 10px;
                margin-right: 20px;
            }
            .claimsData-container-data-section-double {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                width: 31%;
                margin-bottom: 10px;
            }
                .claimsData-container-data-section-double > * {
                    width: 47.5%;
                }
            .claimsData-container-data-section-status {
                display: flex;
                flex-direction: column;
                width: fit-content;
            }
                .claimsData-container-data-section-status-label {
                    color: var(--gray700);
                    margin: 0;
                    padding: 0;
                    margin-bottom: 5px;
                    opacity: 0.6;
                }
                .claimsData-container-data-section-status-data {
                    color: var(--gray700);
                    padding: 2.5px 5px;
                    border-radius: 5px;
                }
    .claimsData-container-functions {
        display: flex;
        flex-direction: column;
        width: 25%;
    }
        .claimsData-container-functions-statuses {
            display: flex;
            flex-direction: row;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: var(--gray300);
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 50px;
        }
            .claimsData-container-functions-statuses-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 24%;
            }
                .claimsData-container-functions-statuses-item-lottie {
                    margin-top: -70px;
                    margin-bottom: -40px;
                }
                .claimsData-container-functions-statuses-item-data {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    background-color: var(--white);
                    border-radius: 10px 10px 0px 0px;
                    height: 30px;
                    z-index: 10;
                }
                    .claimsData-container-functions-statuses-item-data-label {
                        
                    }
        .claimsData-container-functions-title {
            color: var(--gray300);
            margin-bottom: 10px;
        }
        .claimsData-container-functions-download {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: var(--primary500);
            border-radius: 20px;
            padding: 10px 20px;
            cursor: pointer;
            position: relative;
            display: flex;
            width: 50%;
            margin-left: auto;
            margin-bottom: 60px;
            margin-top: 20px;
        }   
            /* Tooltip text */
            .claimsData-container-functions-download .claimsData-container-functions-download-tooltiptext {
                width: 200px;
                bottom: 100%;
                left: 50%;
                margin-left: -100px;
                visibility: hidden;
                background-color: black;
                color: #fff;
                text-align: center;
                padding: 5px 0;
                border-radius: 6px;
                margin-bottom: 10px;
            
                /* Position the tooltip text - see examples below! */
                position: absolute;
                z-index: 2;
            }
            
            /* Show the tooltip text when you mouse over the tooltip container */
            .claimsData-container-functions-download:hover .claimsData-container-functions-download-tooltiptext {
                visibility: visible;
            }
            .claimsData-container-functions-download .claimsData-container-functions-download-tooltiptext::after {
                content: " ";
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: black transparent transparent transparent;
            }
        .claimsData-container-functions-download:active {
            transform: scale(0.95);
            background: var(--primary400);
        }
            .claimsData-container-functions-download-text {
                font-style: normal;
                font-weight: 700;
                font-size: 0.95rem;
                color: var(--white);
                margin: 0;
            }

    .claimsData-container-functions-loader {
        width: 20px;
        height: 20px;
        border: 3px solid var(--white);
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        }
        @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        }