.userCotizacionesComponent-container {
    display: flex;
    flex-direction: row;
    width: 75%;
    background-color: 'transparent';
    border: 1px solid #C4C4C4;
    border-radius: 15px;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 20px;
    margin: 20px 0;
}
    .userCotizacionesComponent-container-data {
        color: var(--gray700);
        width: 100%;
    }