.genericModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .genericModal-container-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: var(--gray700);
        margin: 25px 0;
    }
    .genericModal-container-buttonWrapper {
        width: 40%;
    }