.notification-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    right: 20px;
    margin-top: 5px;
    border-radius: 5px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    z-index: 999999;
    width: 310px;
    padding: 5px 5px;
    transition: all 1s ease;
  }
    .notification-container-text {
      color: var(--gray600);
      width: 80%;
    }