.emptyList-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    color: var(--gray300);
}
    .emptyList-container-text {
        text-align: center;
        color: var(--gray300);
        margin-top: 20px;
    }