.cardList-container {

}
    .cardList-container-labels {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
    }
        .cardList-container-labels-title {
            color: var(--gray700);
        }
        .cardList-container-labels-label {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            margin: 0;
            color: var(--primary500);
            cursor: pointer;
        }
        .cardList-container-labels-label:active {
            transform: scale(0.9);
            color: var(--primary400);
        }
    .cardList-container-loading {
        
    }
    .cardList-container-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .cardList-container-emptyList {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .cardList-container-emptyList-title {
            font-style: normal;
            font-weight: 300;
            font-size: 1.2rem;
            color: var(--gray700);
            margin: 10px 0;
        }