.codCupCard-container {
    display: flex;
    flex-direction: row;
    background: var(--primary200);
    padding-top: 20px;
    padding-bottom: 20px;
}
    .codCupCard-container-cupons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
        color: var(--secondary500);
    }
        .codCupCard-container-cupons-value {
            font-style: normal;
            font-weight: 700;
            font-size: 1.5rem;
            margin: 0;
            margin-top: 10px;
        }
    .codCupCard-container-data {
        display: flex;
        flex-direction: column;   
        margin-right: 40px;
    }
        .codCupCard-container-data-title {
            font-style: normal;
            font-weight: 700;
            font-size: 1.2rem;
            margin: 0;
            color: var(--gray700);
        }
        .codCupCard-container-data-subTitle {
            font-style: normal;
            font-weight: 500;
            font-size: 1.15rem;
            margin: 5px 0;
            color: var(--gray700);
        }
        .codCupCard-container-data-vence {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            margin: 0;
            color: var(--gray700);
        }
    .codCupCard-container-line {
        width: 1px;
        height: 70px;
        background-color: var(--gray300);
    }