.detalleItemCard-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .detalleItemCard-container-title {
        color: var(--gray400)
    }
    .detalleItemCard-container-groupList {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
    }
        .funnel {
            border: 1px solid #C4C4C4;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
        }
    .detalleItemCard-container-list {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
        .detalleItemCard-container-list-item {
            margin-bottom: 20px;
        }