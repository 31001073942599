.passwordRecoveryForm-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
    .passwordRecoveryForm-container-main {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .passwordRecoveryForm-container-main-inputWrapperContra {
            margin-top: 10px;
            width: 300px;
        }
            .passwordRecoveryForm-container-main-inputWrapperContra-underlineContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 2.5px;
                margin-left: 10px;
                width: 400px;
            }
                .passwordRecoveryForm-container-main-inputWrapperContra-underlineContainer-text {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.7rem;
                    color: var(--gray700);
                    padding: 0;
                    margin: 0;
                }
        .passwordRecoveryForm-container-main-inputWrapperReContra {
            margin-top: 30px;
            margin-bottom: 40px;
            width: 300px;
        }
        .passwordRecoveryForm-container-main-logo {
            width: 20%;
        }
        .passwordRecoveryForm-container-main-title {
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            color: var(--gray700);
            margin: 0;
            padding: 0;
            margin-top: 20px;
        }
        .passwordRecoveryForm-container-main-subTitle {
            font-style: normal;
            font-weight: 300;
            font-size: 1.25rem;
            color: var(--gray700);
            padding: 0;
            margin: 0;
            margin-top: 10px;
            margin-bottom: 40px;
            text-align: center;
        }
        .passwordRecoveryForm-container-main-buttonWrapper {
            width: 30%;
        }
        .passwordRecoveryForm-container-main-emailWrapper {
            width: 50%;
            margin-bottom: 30px;
        }
        .passwordRecoveryForm-container-main-codeContainer {
            display: flex;
            margin-bottom: 40px;
        }
            .passwordRecoveryForm-container-main-codeContainer-inputContainer {
                width: 55px;
                height: 70px;
                border: 2px solid transparent;
                box-sizing: border-box;
                margin: 0 4px;
            }
                .passwordRecoveryForm-container-main-codeContainer-inputContainer-input {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 2.1875rem;
                    text-align: center;
                    color: var(--gray700);
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    border: 2px solid var(--primary500);
                    outline-color: var(--primary500);
                    box-sizing: border-box;
                    border-radius: 15px;
                }
        .passwordRecoveryForm-container-main-resendText {
            font-style: normal;
            font-weight: 300;
            font-size: 1.125rem;
            color: var(--primary500);
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .passwordRecoveryForm-container-main-resendSuccessText {
            font-style: normal;
            font-weight: 300;
            font-size: 0.75rem;
            text-align: center;
            color: var(--successPrimary);
            margin: 0;
            margin-top: 5px;
            margin-bottom: 30px;
        }
    .passwordRecoveryForm-container-cardsContainer {
        width: 50%;
        background-color: var(--primary200);
    }
        .passwordRecoveryForm-container-cardsContainer-img {
            margin: 0 auto;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }