.tableHeader-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 10px;
    padding-right: 10px;
    z-index: 2;
    background-color: var(--gray50);
    position: fixed;
    right: 10px;
}